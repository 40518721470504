.home {
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Inclui padding e border no total width e height */
}

.intro, .contact-section-home {
  margin: 0; /* Remove qualquer margem */
  padding: 0; /* Remove qualquer padding */
  box-sizing: border-box; /* Inclui padding e border no total width e height */
}

.intro {
  display: flex;
  flex-direction: column; /* Adicionado para empilhar conteúdo verticalmente */
  justify-content: center;
  align-items: center;
  padding: 75px 20px;
  min-height: 100vh; /* Altere para 100vh para ocupar a altura total da janela */
  background-image: url('../assets/images/intro-bg.gif'); /* Substitua pela URL do seu GIF */
  background-size: cover;
  background-position: center;
  color: #FFFFFF;
  position: relative;
}

.intro::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Overlay mais claro para não ocultar o header */
}

.intro-content {
  position: relative;
  z-index: 1;
  text-align: center;
}

.intro h1 {
  color: #FFFFFF;
  margin: 0;
}

.intro p {
  font-size: 1.2em;
  color: #FFFFFF;
  margin: 0;
  text-align: center;
  padding: 10px 10px;
  margin-left: 30vh;
  margin-right: 30vh;
}

.intro a {
  display: inline-block;
  background-color: #50E2F2;
  color: #0D051A;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  margin-top: 20px;
}

.intro a:hover {
  background-color: #0D051A;
  color: #50E2F2;
}

.features {
  padding-top: 50px; /* Espaço para os cards começarem */
  background-color: inherit; /* Herda o fundo da seção pai */
}

.cards-feature {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 30px 0;
}

.card-feature {
  background-color: #0D051A;
  border-radius: 10px;
  padding: 20px;
  margin: 20px;
  width: 300px;
  color: #F2F2F2;
  text-align: center;
  flex: 1 1 calc(33% - 40px);
  box-sizing: border-box;
  margin-top: 20px;
  margin-bottom: 140px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  
}

.card-feature i {
  font-size: 2em;
  margin-bottom: 10px;
}

.services-home {
  background-color: #F2F2F2;
  color: #013440;
  padding: 50px 20px;
  position: relative;
  z-index: 1;
  text-align: center;
}

.services-section-home {
  padding: 50px 20px;
}

.cards-service {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 0; /* Remove qualquer margem */
  padding: 0; /* Remove qualquer padding */
}

.card-service {
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 20px;
  margin: 20px;
  width: 300px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  flex: 1 1 calc(33% - 40px);
}

.card-service i {
  font-size: 2em;
  margin-bottom: 10px;
  color: #50E2F2;
}

.card-service a {
  display: inline-block;
  background-color: #50E2F2;
  color: #0D051A;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  margin-top: 20px;
}

.card-service a:hover {
  background-color: #0D051A;
  color: #50E2F2;
}

.contact-section-home {
  padding: 50px 20px;
  background-color: #0D051A;
  color: #F2F2F2;
  text-align: center;
}

.contact-section-home h2 {
  color: #50E2F2;
  margin-bottom: 30px; /* Adiciona espaçamento abaixo do título */
}

/* Alteração: Centralizar o formulário e melhorar a aparência */
.contact-section-home form {
  display: flex;
  flex-direction: column; /* Alterado para coluna para centralizar verticalmente */
  align-items: center; /* Alinha o formulário ao centro */
  max-width: 800px;
  margin: 0 auto;
}

.form-group {
  width: 100%; /* Ocupa 100% da largura do container */
  max-width: 400px; /* Largura máxima para cada grupo de formulário */
  margin: 10px 0; /* Margem entre os grupos de formulário */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.form-group label {
  width: 100%; /* Largura total para a label */
  margin-bottom: 5px; /* Margem inferior para separar label do input */
  text-align: left; /* Alinha o texto à esquerda */
}

.form-group input,
.form-group textarea {
  width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #50E2F2;
    border-radius: 5px;
    background-color: #013440;
    color: #F2F2F2;
}

.contact-section-home .form-group textarea {
  height: 90px; /* Altura do campo de texto */
}

.contact-section-home .form-group button {
  background-color: #50E2F2;
  color: #0D051A;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  align-self: center; /* Centraliza o botão */
}

.contact-section-home .form-group button:hover {
  background-color: #0D051A;
  color: #50E2F2;
}

.contact-info-home {
  color: #013440;
  margin-top: 30px;
}

.contact-info-home p {
  color: #F2F2F2; /* Garantir que o texto do contato fique visível */
  margin-bottom: 40px; /* Adiciona um espaçamento ao final da página */
}

@media (max-width: 768px) {
  .cards-feature, .cards-service {
    flex-direction: column;
    align-items: center;
  }

  .card-feature, .card-service {
    width: 100%;
    max-width: 300px;
    margin: 20px;
  }

  .form-group {
    flex: 1 1 100%;
  }
  .form-group input,
.form-group textarea {
  width: 39vh;
}

.intro p {
  margin-left: 10px;
  margin-right: 10px;
}
}
@media (max-width: 400px) {
  .services-section-home {
    padding: 3px ;
  }
}
@media (max-width: 376px) {
  .services-section-home {
    padding: 1px ;
  }
}

@media (max-width: 1024px) {
  .intro p {
    margin-left: 20px;
    margin-right: 20px;
  }
}

