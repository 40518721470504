.about {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.section-bg-video {
  position: relative;
  width: 100%;
  height: 300px; /* Ajuste a altura conforme necessário */
  overflow: hidden;
}

.background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
}

.about h1,
.about h2 {
  color: #FFFFFF;
}

.about p {
  color: #FFFFFF;
}

.about a {
  display: inline-block;
  background-color: #50E2F2;
  color: #0D051A;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  margin-top: 20px;
}

.about a:hover {
  background-color: #0D051A;
  color: #50E2F2;
}

.container.content {
  padding: 50px 15px;
  background: inherit;
  background-color: #F2F2F2;
}

.sobre_nos_section {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

.sobre_nos_text,
.sobre_nos_images {
  padding: 15px;
  flex: 1;
}

.sobre_nos_images {
  display: flex;
  justify-content: space-between;
}

.sobre_nos_image {
  width: 100%;
  height: auto;
  margin-bottom: 15px;
}

.vantagens_ti_section {
  display: flex;
  flex-wrap: wrap;
}

.vantagens_section,
.ti_section {
  padding: 15px;
  flex: 1;
}

.fa-ul {
  list-style-type: none;
  padding-left: 0;
}

.fa-ul li {
  margin-bottom: 10px;
  position: relative;
  padding-left: 20px;
  color: #555;
}

.fa-li {
  position: absolute;
  left: 0;
  top: 0;
}

.space {
  margin: 20px 0;
}

.card {
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 20px;
  margin: 20px;
  color: #555;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  flex: 1 1 calc(33% - 40px);
  box-sizing: border-box;
}

.card h3 {
  color: #013440;
  margin-bottom: 20px;
}

.card p {
  color: #555;
}

.card .fa-li {
  color: #555;
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px 0;
}

.card-combined {
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 20px;
  margin: 20px;
  color: #555;
  text-align: left;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
}

.card-combined .left,
.card-combined .right {
  flex: 1 1 45%;
  margin: 10px;
}

.card-combined .left h3,
.card-combined .right h3 {
  color: #013440;
  margin-bottom: 20px;
}

.card-combined .left p,
.card-combined .right p {
  color: #555;
}

.card-combined ul {
  list-style-type: none;
  padding-left: 0;
}

.card-combined ul li {
  margin-bottom: 10px;
  position: relative;
  padding-left: 20px;
  color: #555;
}

.card-combined ul .fa-li {
  position: absolute;
  left: 0;
  top: 0;
  color: #555;
}

@media (max-width: 768px) {
  .sobre_nos_section,
  .vantagens_ti_section {
    flex-direction: column;
  }

  .sobre_nos_text,
  .sobre_nos_images,
  .vantagens_section,
  .ti_section {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .sobre_nos_image {
    margin-bottom: 15px;
    width: 100%;
  }
  
  .card-combined ul li {
    flex-direction: column;
  }

  .card-combined .left,
  .card-combined .right {
    flex: 1 1 100%;
    max-width: 100%;
    margin: 10px 0;
  }
}
