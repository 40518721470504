.header {
  background-color: transparent; /* Começa transparente */
  color: #F2F2F2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 10px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  
  transition: background-color 0.4s ease; /* Adiciona uma transição suave */
}

.header.scrolled {
  background-color: #0D051A; /* Cor de fundo ao rolar */
}

.header a {
  color: #50E2F2;
  font-weight: bold;
}

.header a span {
  color: #F2F2F2;
  font-weight: bold;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo-container a {
  font-size: 1.5em;
  padding-left: 20vh;
  text-decoration: none;
}

.header nav {
  flex-grow: 1;
  text-align: left;
  padding-left: 70vh;
}

.header nav ul {
  list-style: none;
  padding: 0;
  margin: 0; /* Certifique-se de que não há margem */
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  
}

.header nav ul li {
  margin: 0 15px;
  
}

.header nav ul li a {
  color: #F2F2F2;
  text-decoration: none;
  font-weight: bold;
}

.header nav ul li a:hover {
  color: #50E2F2;
}

.client-area {
  background-color: #F2F2F2;
  color: #0D051A !important;
  padding: 5px 15px;
  border-radius: 5px;
  font-weight: bold;
}

.client-area:hover {
  background-color: #50E2F2;
}

.menu-icon {
  display: none;
  font-size: 2em;
  cursor: pointer;
}

@media (max-width: 768px) {
  .header nav {
    position: absolute;
    top: 80px;
    right: 0;
    background-color: #0D051A;
    width: 100%;
    height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
  }

  .header nav.open {
    transform: translateX(0);
  }

  .header nav ul {
    flex-direction: column;
    justify-content: center;
  }

  .header nav ul li {
    margin: 20px 0;
  }

  .menu-icon {
    display: block;
    margin: 10px 40px;
  }
  
  .logo-container a {
    font-size: 1.5em;
    padding-left: 1vh;
    text-decoration: none;
  }
}
@media (max-width: 1024px) {
  .header nav {
    position: absolute;
    top: 80px;
    right: 0;
    background-color: #0D051A;
    width: 100%;
    height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
  }

  .header nav.open {
    transform: translateX(0);
  }

  .header nav ul {
    flex-direction: column;
    justify-content: center;
  }

  .header nav ul li {
    margin: 20px 0;
  }

  .menu-icon {
    display: block;
    margin: 10px 40px;
  }
  
  .logo-container a {
    font-size: 1.5em;
    padding-left: 1vh;
    text-decoration: none;
  }
}
