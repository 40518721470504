.services {
  padding: 50px 20px;
  text-align: center;
}
.services h2 {
  color: #FFFFFF;
}

.services-section {
  margin: 0 auto;
  max-width: 1200px;
}

.cards-service {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.card-service {
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 20px;
  margin: 20px;
  width: 300px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  flex: 1 1 calc(33% - 40px);
  transition: max-height 0.5s ease, padding 0.5s ease;
  overflow: hidden;
}

.card-service h3 {
  margin-top: 10px;
  font-size: 1.2em;
}



.card-service p {
  font-size: 1em;
  color: #555;
}

.card-service .expanded-content {
  margin-top: 10px;
}

.card-service ul {
  list-style-type: none;
  padding: 0;
  margin-top: 10px;
}

.card-service ul li {
  margin-bottom: 5px;
  color: #333;
}

.card-service button {
  background-color: #50E2F2;
  color: #0D051A;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s ease;
}

.card-service button:hover {
  background-color: #0D051A;
  color: #50E2F2;
}

@media (max-width: 768px) {
  .cards-service {
    flex-direction: column;
    align-items: center;
  }

  .card-service {
    width: 100%;
    max-width: 300px;
  }
}
.services {
  padding: 50px 20px;
  text-align: center;
  
}
.services h1 {
color: #F2F2F2;
}
.services p {
  color: #F2F2F2;
  }

.services-section {
  margin: 0 auto;
  max-width: 1200px;
}

.cards-service {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.card-service {
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 20px;
  margin: 20px;
  width: 300px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  flex: 1 1 calc(33% - 40px);
  transition: max-height 0.5s ease, padding 0.5s ease;
  overflow: hidden;
}

.card-service h3 {
  margin-top: 10px;
  font-size: 1.2em;
}

.card-service p {
  font-size: 1em;
  color: #555;
}

.card-service .expanded-content {
  margin-top: 10px;
}

.card-service ul {
  list-style-type: none;
  padding: 0;
  margin-top: 10px;
}

.card-service ul li {
  margin-bottom: 5px;
  color: #333;
}

.card-service button {
  background-color: #50E2F2;
  color: #0D051A;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s ease;
}

.card-service button:hover {
  background-color: #0D051A;
  color: #50E2F2;
}

@media (max-width: 768px) {
  .cards-service {
    flex-direction: column;
    align-items: center;
  }

  .card-service {
    width: 100%;
    max-width: 300px;
  }
}
