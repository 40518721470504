@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  background-color: #110826;
  box-shadow: #000000;
  color: #013440;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

#root {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0;
  padding: 0;
}

.App {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0;
  padding: 0;
}

main {
  flex: 1;
  margin: 0;
  padding: 0;
   
}

footer {
  background-color: #0D051A;
  color: #F2F2F2;
  padding: 15px;
  text-align: center;
  position: fixed;
  width: 100%;
  bottom: 0;
}

.dashboard {
  display: flex;
}

.sidebar {
  width: 250px;
  background-color: #f4f4f4;
  padding: 20px;
}

.dashboard-content {
  flex: 1;
  padding: 20px;
}

.sidebar h2 {
  margin-top: 0;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul li {
  margin: 10px 0;
}

.sidebar ul li a {
  text-decoration: none;
  color: #333;
}

.sidebar ul li a:hover {
  text-decoration: underline;
}
