.footer-light {
  background-color: #0D051A;
  padding: 20px 0;
  text-align: center;
  font-size: 14px;
  color: #F2F2F2;
  position: relative;
  bottom: 0;
  width: 100%;
}

.logo-container1 {
  color: #50E2F2;
  font-weight: bold;
  text-decoration: none;
  font-size: 24px;
}

.logo-container1 span {
  color: #F2F2F2;
}

.footer-light .container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.footer-light .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start; /* Alinha os itens ao topo */
}

.footer-light .col-lg-4, 
.footer-light .col-lg-3, 
.footer-light .col-lg-5 {
  flex: 1;
  min-width: 250px;
  padding: 10px;
  color: #F2F2F2;
}

.footer-light h5 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #F2F2F2;
}

.footer-light ul {
  list-style: none;
  padding: 0;
}

.footer-light ul li {
  margin-bottom: 5px;
}

.footer-light ul li a {
  color: #50E2F2;
  text-decoration: none;
}

.footer-light ul li a:hover {
  text-decoration: none;
}

.footer-light img.partner-image {
  max-width: 35%;
  height: auto;
  display: inline-block;
}

.footer-light .social-icons a {
  color: #6c757d;
  margin: 0 10px;
  text-decoration: none;
}

.footer-light .social-icons a:hover {
  color: #007bff;
}

.copy-text {
  margin-top: 20px;
  font-size: 12px;
  color: #F2F2F2;
}

.s1 span, .s1 a {
  display: block;
  margin-bottom: 10px;
  color: #50E2F2;
  text-decoration: none;
  font-style: normal;
  font-weight: normal;
  text-transform: none;
}

@media (max-width: 768px) {
  .footer-light .row {
    flex-direction: column;
    text-align: left;
    align-items: center; /* Centraliza os itens no modo mobile */
  }

  .footer-light .col-lg-4, 
  .footer-light .col-lg-3, 
  .footer-light .col-lg-5 {
    min-width: 100%;
    padding: 10px 0;
    text-align: center;
  }

  .footer-light .social-icons {
    text-align: center;
    margin-top: 20px;
  }

  .footer-light .social-icons a {
    display: inline-block;
    margin: 0 5px;
  }
}
