
  
  .container2 {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin-top: 20vh;
    margin-bottom: 10vh;
  }
  
  .login-form {
    background-color: #F2F2F2;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
  }
  
  .login-form h2 {
    color: #013440;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .login-form label {
    display: block;
    color: #013440;
    margin-bottom: 5px;
  }
  
  .login-form input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #013440;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  .login-form button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #013440;
    color: #F2F2F2;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .login-form button:hover {
    background-color: #50E2F2;
    color: #0D051A;
  }
  
  header, footer {
    background-color: #0D051A;
    color: #F2F2F2;
    text-align: center;
    padding: 10px 0;
  }
  
  header a, footer a {
    color: #50E2F2;
    text-decoration: none;
  }
  
  header a:hover, footer a:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 768px) {
    .login-form {
      padding: 15px;
      margin: 10px;
    }
  
    .login-form input {
      padding: 8px;
      margin-bottom: 15px;
    }
  
    .login-form button {
      padding: 8px;
    }
  }
  