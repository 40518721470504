.contact {
  padding: 50px 20px;
  margin-top: 10vh;
}

.contact-section {
  margin: 0 auto;
  max-width: 1200px;
  text-align: left;
}

.contact-section h2 {
  color: #50E2F2;
  margin-bottom: 20px;
}

.contact-section p {
  font-size: 1.2em;
  margin-bottom: 40px;
  color: #f2f2f2;
}

.contact-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.contact-details {
  flex: 1;
  margin-right: 20px;
}

.contact-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  color: #f2f2f2;
}

.contact-item a {
  text-decoration: none;
  color: inherit;
}

.contact-item span {
  margin-left: 15px;
  font-size: 1.2em;
}

.contact-item svg {
  color: #f2f2f2;
}

.map {
  flex: 1;
  margin-left: 20px;
  max-width: 600px;
}

@media (max-width: 768px) {
  .contact-content {
    flex-direction: column;
    align-items: center;
  }

  .contact-details {
    margin-right: 0;
    margin-bottom: 20px;
    width: 100%;
  }

  .map {
    margin-left: 0;
    width: 100%;
  }

  .contact-item {
    justify-content: center;
  }
}
